import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-section-loader',
  templateUrl: './section-loader.component.html',
  styleUrls: ['./section-loader.component.scss'],
})
export class SectionLoaderComponent implements OnInit, OnChanges {
  @Input() public display: boolean;
  @Input() public error: boolean;
  @Input() public label: string;

  public timer$: Observable<number>;

  public ngOnChanges(): void {
    if (this.display) {
      this.timer$ = of(0);
    } else {
      this.timer$ = timer(0, 1000).pipe(map((tick: number) => tick));
    }
  }

  public ngOnInit(): void {
    this.timer$ = timer(0, 1000).pipe(map((tick: number) => tick));
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationResultType } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import { CurrentUserQuery } from 'src/app/state';

@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss'],
})
export class LoginMFAComponent implements OnInit {
  public codeVerificationForm: FormGroup;
  public formControls: {
    verificationCode: FormControl,
  };
  public phoneNumber: string;
  public spin: boolean = false;
  public spinnerLabel: string;

  public constructor(
    private authenticationService: AuthenticationService,
    private currentUserQuery: CurrentUserQuery,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
  ) { }

  public ngOnInit(): void {
    this.formControls = {
      verificationCode: new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]*'),
      ]),
    };
    this.codeVerificationForm = this.formBuilder.group(this.formControls);
    this.phoneNumber = this.currentUserQuery.getCognitoPhoneNumber();
  }

  public async resendVerificationCode(): Promise<void> {
    this.spinnerLabel = 'Resending Verification Code';
    this.spin = true;
    const resendCodeResult = await this.authenticationService.resendSignInVerificationCode();
    if (resendCodeResult.wasCodeSent) {
      this.toastrService.info(`Authentication code resent to ${this.phoneNumber}`);
    } else if (resendCodeResult.error) {
      this.toastrService.error(resendCodeResult.error.message);
    } else {
      if (resendCodeResult.authenticationResult.type === AuthenticationResultType.IncorrectPassword) {
        this.toastrService.error('Missing credentials. Please login again.');
      }
      await this.authenticationService.navigateBasedOnAuthentication(resendCodeResult.authenticationResult);
    }
    this.spin = false;
  }

  public async submitVerificationCode(): Promise<void> {
    this.spinnerLabel = 'Processing Verification Code';
    this.spin = true;
    const code = this.formControls.verificationCode.value;
    const authenticationResult = await this.authenticationService.confirmSignIn(code);
    if (authenticationResult.type === AuthenticationResultType.IncorrectMFACode) {
      this.toastrService.error('Invalid verification code provided, please try again.');
    } else {
      await this.authenticationService.navigateBasedOnAuthentication(authenticationResult);
    }
    this.spin = false;
  }
}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { RouteLoadingService } from '@app/shared/services/route-loading.service';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-route-loading',
  templateUrl: './route-loading.component.html',
  styleUrls: ['./route-loading.component.scss'],
  providers: [RouteLoadingService],
})
export class RouteLoadingComponent implements OnInit, OnChanges {
  @Input() public delay = 2;
  @Input() public spin: boolean = false;
  @Input() public label: string;

  public isOverlayVisible$: Observable<boolean>;
  public timer$: Observable<number>;
  public constructor(
    private routeLoadingService: RouteLoadingService,
  ) { }

  public ngOnChanges(): void {
    if (this.spin) {
      this.timer$ = of(0);
    } else {
      this.timer$ = timer(0, 1000).pipe(map((tick: number) => tick));
    }
    this.isOverlayVisible$ = of(this.spin);
  }

  public ngOnInit(): void {
    if (this.spin) {
      this.isOverlayVisible$ = of(this.spin);
    } else {
      this.isOverlayVisible$ = this.routeLoadingService.hasRouteLoadingTimeExceeded(this.delay);
    }
    this.timer$ = timer(0, 1000).pipe(map((tick: number) => tick));
  }
}

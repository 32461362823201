import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MarginalsModule } from '../marginals/marginals.module';
import { FormControlsModule } from '../shared/components/forms/form-controls.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { TermsOfUseModalComponent } from './components/terms-of-use-modal/terms-of-use-modal.component';
import { TermsOfUseSignatureComponent } from './components/terms-of-use-signature/terms-of-use-signature.component';
import { TermsOfUseComponent } from './container/terms-of-use/terms-of-use.component';
import { TermsOfUseRoutingModule } from './terms-of-use.routing';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [
    TermsOfUseModalComponent,
    TermsOfUseSignatureComponent,
    TermsOfUseComponent,
  ],
    exports: [
        TermsOfUseModalComponent,
        TermsOfUseSignatureComponent,
        TermsOfUseComponent,
    ],
  imports: [
    CommonModule,
    FormControlsModule,
    MarginalsModule,
    PipesModule,
    TermsOfUseRoutingModule,
    SharedModule,
  ],
})
export class TermsOfUseModule { }

import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationResultType } from 'src/app/auth/models';
import { AuthenticationChallenge, AuthenticationChallengeType } from 'src/app/auth/models/authentication-challenge.model';
import { AuthenticationService } from 'src/app/auth/services/authentication.service';
import {
  FederatedRedirectModalComponent,
} from 'src/app/login/components/login-form/federated-redirect-modal/federated-redirect-modal.component';
import { RememberUserAction } from 'src/app/shared/models/pux/enum';
import { PuxValidators } from 'src/app/shared/utils/validators';
import { CurrentUserQuery } from 'src/app/state';

import { PageTitle } from '../../../shared/app-constants/page-title.enum';
import {
  FederatedPasswordUserNullModalComponent,
} from './federated-password-user-null-modal/federated-password-user-null-modal.component';

@Component({
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @ViewChild(FederatedRedirectModalComponent, { static: true }) public federatedRedirectModal: FederatedRedirectModalComponent;
  @ViewChild(FederatedPasswordUserNullModalComponent, { static: true }) public federatedPasswordUserNullModal: FederatedPasswordUserNullModalComponent;

  public formControls: {
    email: FormControl,
    rememberMe: FormControl,
    password?: FormControl,
  };
  public loginForm: FormGroup;
  public enableLoginForm: EventEmitter<void> = new EventEmitter<void>();
  public AuthenticationChallengeType = AuthenticationChallengeType;
  public authenticationChallenge: AuthenticationChallenge;
  public authenticationChallengeIdpOverrides: any;
  public spin: boolean = false;
  public spinnerLabel: string;
  private identityProvider: string;

  public constructor(
    private authenticationService: AuthenticationService,
    private currentUserQuery: CurrentUserQuery,
    private formBuilder: FormBuilder,
    private router: Router,
    private titleService: Title,
    private toastrService: ToastrService,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle(PageTitle.LOGIN);
    this.resetComponent();
  }

  public async submitLogin(): Promise<void> {
    switch (this.authenticationChallenge.challengeType) {
      case AuthenticationChallengeType.Password:
        await this.signIn();
        break;
      case AuthenticationChallengeType.SAML:
      case AuthenticationChallengeType.Unknown:
        await this.getUserAuthenticationChallenge();
        this.enableLoginForm.emit();
        break;
      default:
        this.toastrService.error('Something went wrong.');
        await this.router.navigate(['/logout']);
        break;
    }
    if (document.getElementById('focus-anchor')) {
      document.getElementById('focus-anchor').focus();
    }
  }

  public back(): void {
    this.enableLoginForm.emit();
    this.resetComponent();
    if (document.getElementById('focus-anchor')) {
      document.getElementById('focus-anchor').focus();
    }
  }

  public setUserAuthenticationChallenge(challengeType: AuthenticationChallengeType): void {
    this.authenticationChallenge = { challengeType };
    this.configureFormControls();
  }

  private configureFormControls(): void {
    const rememberedEmailAddress = this.currentUserQuery.getRememberedEmailAddress();
    if (!this.formControls) {
      this.formControls = {
        email: new FormControl(rememberedEmailAddress, [
          Validators.required,
          Validators.email,
          PuxValidators.isEmailAddress,
        ]),
        rememberMe: new FormControl(!!rememberedEmailAddress),
      };
      this.loginForm = this.formBuilder.group(this.formControls);
    }

    switch (this.authenticationChallenge.challengeType) {
      case AuthenticationChallengeType.Password:
        const passwordControl = new FormControl(null, [
          Validators.required,
        ]);
        this.formControls.password = passwordControl;
        this.loginForm.addControl('password', passwordControl);
        break;
      case AuthenticationChallengeType.SAML:
        this.openFederatedRedirect(this.authenticationChallenge.identityProvider, this.authenticationChallengeIdpOverrides);
        break;
      case AuthenticationChallengeType.Unknown:
        this.loginForm.removeControl('password');
        break;
      default:
        this.toastrService.error('Something went wrong.');
        break;
    }
  }

  private openFederatedRedirect(identityProvider: string, idpOverrides?: any): void {
    this.identityProvider = identityProvider;
    const emailAddress = this.formControls.email.value.toLowerCase();
    this.federatedRedirectModal.open(identityProvider, emailAddress, idpOverrides);
  }

  private resetComponent(): void {
    this.identityProvider = null;
    this.authenticationChallenge = { challengeType: AuthenticationChallengeType.Unknown };
    this.authenticationChallengeIdpOverrides = null;
    this.configureFormControls();
  }

  // Make an API call to see what auth challenge to present to the user
  private async getUserAuthenticationChallenge(): Promise<void> {
    this.spinnerLabel = 'Signing In';
    this.spin = true;
    const emailAddress = this.formControls.email.value.toLowerCase();
    this.authenticationChallenge = await this.authenticationService.getAuthenticationChallengeType(emailAddress);
    this.authenticationChallengeIdpOverrides = this.authenticationChallenge.idpOverrides;
    this.configureFormControls();
    this.spin = false;
  }

  private async signIn(): Promise<void> {
    this.spinnerLabel = 'Signing In';
    this.spin = true;
    // the '...' in the text below is intentional to make the text different from the .announce() call above. Otherwise the screen reader won't announce the same text twice
    const emailAddress = this.formControls.email.value.toLowerCase();
    const password = this.formControls.password.value;
    const rememberMe = this.formControls.rememberMe.value ? RememberUserAction.Remember : RememberUserAction.Forget;
    const authenticationResult = await this.authenticationService.signIn(emailAddress, password, rememberMe);
    switch (authenticationResult.type) {
      case AuthenticationResultType.IncorrectPassword:
        this.toastrService.error('The email and password combination you entered is incorrect. Please try again.');
        this.enableLoginForm.emit();
        break;
      case AuthenticationResultType.Timeout:
        this.toastrService.error('Unable to connect to the server. Please try again.');
        this.enableLoginForm.emit();
        break;
      case AuthenticationResultType.UserNotFound:
        if (this.identityProvider) {
          this.federatedPasswordUserNullModal.open(this.identityProvider, emailAddress, this.authenticationChallengeIdpOverrides);
        } else {
          this.toastrService.error('User does not exist.');
        }
        this.enableLoginForm.emit();
        break;
      default:
        await this.authenticationService.navigateBasedOnAuthentication(authenticationResult);
        break;
    }
    this.spin = false;
  }
}

<div class="page-loader" *ngIf="display">
  <div class="loader-content">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div *ngIf="timer$ | async as seconds" aria-live="assertive">
    <span *ngIf="seconds > 1" class="sr-only">{{label}}</span>
  </div>
</div>

// AUTOGENERATED BY FUNCTION genubamodel.js
export interface CommandBase {
  id: string;
  eventCorrelationId: string;
  jobId?: string;
  producerId: string;
  sequenceId?: number;
  version: number;
  type: string;
  created: string;
  createdBy: string;
  createdById: string;
}

export interface EntityBase {
  id?: string;
  parentId?: string;
  parentType?: ParentType;
  version?: number;
  created?: string;
  createdBy?: string;
  createdById?: string;
  updated?: string;
  updatedBy?: string;
  updatedById?: string;
  currentState?: string;
  lastTransition?: string;
  externalWorkflowId?: string;
  eventNotes?: string;
  transactionLockId?: string;
}

export interface HoldInactivationCommand extends CommandBase {
  data?: HoldInactivation;
}

export interface PaymentAccountCommand extends CommandBase {
  data?: PaymentAccount;
}

export interface PaymentElectionCommand extends CommandBase {
  data?: PaymentElection;
}

export interface PaymentElectionProfileCommand extends CommandBase {
  data?: PaymentElectionProfile;
}

export interface PaymentAccountGroupCommand extends CommandBase {
  data?: PaymentAccountGroup;
  enrollmentSelections?: EnrollmentSelection[];
}

export interface ObligationCommand extends CommandBase {
  data?: Obligation;
}

export interface ObligationLineItemCommand extends CommandBase {
  data?: ObligationLineItem;
}

export interface CoverageChangeRunCommand extends CommandBase {
  data?: CoverageChangeRun;
}

export interface CoverageChangeStateCommand extends CommandBase {
  data?: CoverageChangeState;
}

export interface CoverageChangeReportLineCommand extends CommandBase {
  data?: CoverageChangeReportLine;
}

export interface BcsRateChangeStateCommand extends CommandBase {
  data?: BcsRateChangeState;
}

export interface HoldInactivation extends EntityBase {
  holdInactivationDate?: string;
  holdInactivationReason?: string;
  eventId: string;
}

export interface CoverageChange {
  changeType?: CoverageChangeType;
  changeDate?: string;
  fullName?: string;
  individualId?: string;
  employeeId?: string;
  division?: string;
  subdivision?: string;
  class?: string;
  relationship?: string;
  dateOfBirth?: string;
  aoName?: string;
  planName?: string;
  carrierEmail?: string;
  tier?: string;
  coverageStartDate?: string;
  coverageEndDate?: string;
}

export interface PaymentAccount extends EntityBase {
  paymentPlanId: string;
  paymentAccountGroupId: string;
  inactivationDate?: string;
  startDate?: string;
  hideFrom?: HideFromType;
  inactivationReason?: string;
  inactivatedOnTimestamp?: string;
}

export interface PaymentElection extends EntityBase {
  paymentPlanId: string;
  paymentAccountGroupId: string;
  startDate: string;
  offeringPriceId: string;
  inactivationDate?: string;
}

export interface PaymentElectionProfile extends EntityBase {
  profileId: string;
  profileType: EventProfileType;
  eventProfileId: string;
}

export interface PaymentAccountGroup extends EntityBase {
  firstEligibilityEventId: string;
  displayName: string;
  hideFrom?: HideFromType;
  inactivationDate?: string;
  serviceOfferingId: string;
  startDate?: string;
  preventInsignificantShortageInactivation?: boolean;
  inactivatedOnTimestamp?: string;
  inactivationReason?: string;
  firstPaymentDate?: string;
  firstObligationPaidDate?: string;
  lastElectionPayload?: ElectionPayload;
}

export interface EnrollmentSelection {
  paymentPlanId?: string;
  profileIds?: string[];
}

export interface ElectionPayload {
  effectiveDate?: string;
  enrollmentSelections?: EnrollmentSelection[];
  submissionPlatform?: SubmissionPlatformType;
}

export interface Obligation extends EntityBase {
  dueDate: string;
  commitDate: string;
  lastDayToPay?: string;
  billingDate?: string;
  remittedOn?: string;
  billingPeriodStart: string;
  billingPeriodEnd: string;
  obligationType: string;
}

export interface ObligationLineItem extends EntityBase {
  amount: number;
  paymentElectionId: string;
  paymentAccountId: string;
  amountType?: ObligationAmountType;
  amountSubType?: string;
  serviceStartDate?: string;
  serviceEndDate?: string;
  paymentPlanName?: string;
  tier?: string;
  description?: string;
  reversingObligationLineItemId?: string;
  originalAmount?: number;
  paymentPlanId?: string;
}

export interface CoverageChangeRun extends EntityBase {
  runTimestamp: string;
  individualCount: number;
  failedToQueueCount?: number;
  failedIndividualCount?: number;
  stateUpdatedIndividualCount?: number;
  stateNotUpdatedIndividualCount?: number;
}

export interface CoverageChangeState extends EntityBase {
  runId: string;
  runTimestamp: string;
  coverageState?: CoverageState;
}

export interface CoverageChangeReportLine extends EntityBase {
  tpa: TPAPartnerType;
  clientExternalId: string;
  clientName: string;
  action: CoverageChangeReportLineActionType;
  recordedDate: string;
  individualId: string;
  profileType: EventProfileType;
  dependentId: string;
  firstName: string;
  lastName: string;
  individualExternalId: string;
  ssn?: string;
  employeeName?: string;
  employeeSsn?: string;
  relationship: string;
  dateOfBirth?: string;
  employmentInfoId: string;
  divisionName?: string;
  subdivisionName?: string;
  className?: string;
  paymentPlanId: string;
  externalOfferingDescription?: string;
  planName: string;
  planExternalId: string;
  clientProvidedPlanId?: string;
  planPolicyNumberGroupId?: string;
  carrierName?: string;
  carrierEmail?: string;
  tier: string;
  coverageStartDate?: string;
  coverageEndDate?: string;
  eventType: string;
  coverageChangeStateId: string;
  paymentAccountGroupId: string;
  paymentAccountId: string;
  paymentElectionId: string;
  paymentElectionProfileId: string;
  offeringPriceId: string;
  originalEligibilityEventId: string;
  applicableModificationEligibilityEventId: string;
  eligibilityEventOfferingId: string;
}

export interface BcsRateChangeState extends EntityBase {
  state: RateChangeState;
}

export interface RateChangeState {
  billingPeriodRates: PlanPeriodRate[];
}

export interface Criteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
  groupCriteria?: GroupCriteria[];
}

export interface Aggregate {
  key?: string;
  aggregateType?: AggregateType;
  groupBy?: boolean;
}

export interface Error {
  message?: string;
  fields?: object;
}

export interface ErrorBody {
  errors?: object;
}

export interface GroupCriteria {
  key?: string;
  value?: string;
  matchType?: MatchType;
  chainType?: ChainType;
}

export interface PingResponse {
  serverTime: ServerTime;
  services?: Service[];
}

export type SearchCriteria = Criteria[];

export type AggregateCriteria = Aggregate[];

export interface Service {
  name?: string;
  serverTime?: string;
}

export type ServerTime = string;

export interface HoldingAccountBalanceInfo {
  holdingAccountType: HoldingAccountType;
  holdingAccountId: string;
  ledgerBalance: number;
  availableBalance: number;
  designatedBalance: number;
  netUnpaidBalance?: number;
  netOverdueBalance?: number;
}

export interface IndividualPaymentStatus {
  clientId?: string;
  undesignatedHoldingBalance?: number;
  paymentAccountGroups: PaymentAccountGroupEnriched[];
}

export interface PaymentGroupPostingSummary {
  payments?: PaymentGroupPostingPayment[];
  remittances?: PaymentGroupPostingRemittance[];
  commitments?: PaymentGroupPostingCommitment[];
}

export interface PaymentAccountGroupEnriched extends PaymentAccountGroup {
  eligibilityEvent: EventSummary;
  balance: HoldingBalance;
  accounts: PaymentAccountEnriched[];
  projectedPaidThroughDate?: string;
  remittedThroughDate?: string;
  currentTotalElectionAmountNonProrated?: number;
  obligations: ObligationsSummary;
}

export interface PaymentGroupPostingBase {
  amount: number;
  entryPostingId: string;
  scheduledDate: string;
  status: string;
  paymentDetail?: string;
  entryType?: string;
}

export interface PaymentGroupPostingPayment extends PaymentGroupPostingBase {
  paymentMethod?: string;
  postingType?: string;
}

export interface PaymentGroupPostingRemittance extends PaymentGroupPostingBase {
  payee?: string;
  paymentPlanId?: string;
  planName?: string;
}

export interface PaymentGroupPostingCommitment extends PaymentGroupPostingBase {
  paymentAccountId?: string;
  paymentPlanId?: string;
  planName?: string;
}

export interface EventSummary {
  eligibilityEventId: string;
  eventType: string;
  eventDate?: string;
  currentState: string;
  demographics: EventDemographicsSummary;
  firstResponseDate: string;
  lastResponseDate: string;
  offerSentDate?: string;
  electionDueDate?: string;
  earliestEligibilityStartDate: string;
  lastEligibilityEndDate?: string;
  offerings: OfferingSummary[];
  modificationEvents: ModificationEventSummary[];
  hideFrom?: HideFromType;
}

export interface OfferingSummary {
  eligibilityEventOfferingId: string;
  eligibilityStartDate: string;
  eligibilityEndDate?: string;
  planId: string;
  planName: string;
  includedEventProfileIds: string[];
}

export interface ModificationEventSummary {
  eligibilityModificationEventId: string;
  originalEligibilityEventId: string;
  lastEligibilityModificationEventId: string;
  nextEligibilityModificationEventId?: string;
  eventType: string;
  eventDate: string;
  offerings: ModificationOfferingSummary[];
}

export interface ModificationOfferingSummary extends OfferingSummary {
  modifiesEligibilityEventOfferingId?: string;
  isNewPlan?: boolean;
}

export interface EligibilityEventSummary {
  id: string;
  eventType: string;
  currentState: string;
  eligibilityStartDate: string;
  displayName: string;
  demographics: EventDemographicsSummary;
  responseDate: string;
  lastDateToEnroll?: string;
  eligibilityEndMaxDate?: string;
  inactivatedDate?: string;
  projectedPaidThroughDate?: string;
  remittedThroughDate?: string;
  designatedThroughDate?: string;
  amountDue: number;
  currentObligationAmountDue?: number;
  currentNonProratedElectionAmount?: number;
  balance: HoldingBalance;
  enrollments: EnrollmentEnriched[];
  obligations: ObligationEnriched[];
}

export interface Name {
  full: string;
  first: string;
  last: string;
  middle?: string;
}

export interface Address {
  id?: string;
  addressType: AddressType;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country: string;
  zip?: string;
  zipplusfour?: string;
}

export interface EventDemographicProfileSummary {
  eventProfileId: string;
  profileId: string;
  name: Name;
  address?: Address;
  dateOfBirth?: string;
  gender?: GenderType;
  isSmoker?: boolean;
}

export interface EventDemographicsSummary extends EventDemographicProfileSummary {
  retired?: boolean;
  dependents: EventDependentSummary[];
}

export interface EventDependentSummary extends EventDemographicProfileSummary {
  relationship: RelationshipType;
}

export interface RemittanceStagingBalance {
  paymentAccountId: string;
  paymentPlanId: string;
  planName: string;
  balance: number;
}

export interface HoldingBalance {
  totalHoldingBalance: number;
  remittanceStagingAccounts: RemittanceStagingBalance[];
  totalRemittanceStagingAccountBalance: number;
  designated: number;
  credits: number;
}

export interface ObligationsSummary {
  currentAmountDue: number;
  currentLastDayToPay?: string;
  totalPaidToDate?: number;
  totalRemittedToDate?: number;
  dateOfLastPayment?: string;
  billingDate?: string;
  billingPeriodStart?: string;
  billingPeriodEnd?: string;
  endOfLastObligationPeriod?: string;
  obligationSchedule?: string[];
  anticipatedObligationAmounts?: AnticipatedObligationAmount[];
  unpaidObligations: ObligationEnriched[];
  paidObligations: ObligationEnriched[];
  voidObligations?: ObligationEnriched[];
  aggregatedObligations?: ObligationEnriched[];
}

export interface AnticipatedObligationAmount {
  amount: number;
  dueDate: string;
  lineItems: AnticipatedObligationLineItemAmount[];
}

export interface AnticipatedObligationLineItemAmount {
  paymentElectionId: string;
  amount: number;
  planName?: string;
  tier?: string;
}

export interface ObligationEnriched extends Obligation {
  amountDue?: number;
  estimatedAmountDesignated?: number;
  paymentStatus: PaymentStatusType;
  lineItems: ObligationLineItemEnriched[];
  late?: boolean;
  missed?: boolean;
  stagedForRemittance?: boolean;
  remitted?: boolean;
  upcoming?: boolean;
  eligibleForRollback?: boolean;
  initialObligation?: boolean;
  isLastScheduledObligation?: boolean;
  endOfObligationPeriod?: string;
}

export interface ObligationLineItemEnriched {
  parentId?: string;
  amount: number;
  paymentElectionId?: string;
  paymentAccountId?: string;
  serviceStartDate?: string;
  serviceEndDate?: string;
  description?: string;
  planName: string;
  tier: string;
  basePrice: number;
  feeAmount?: number;
  adjustmentAmount?: number;
  prorated?: boolean;
}

export interface PaymentAccountEnriched extends PaymentAccount {
  planName: string;
  projectedPaidThroughDate?: string;
  remittedThroughDate?: string;
  currentElectionAmountNonProrated?: number;
  currentElections: ElectionEnriched[];
  eligibilityStartDate?: string;
  eligibilityEndDate?: string;
  upcomingElections: ElectionEnriched[];
  historicalElections: ElectionEnriched[];
  profileHistory: ProfileHistoryItem[];
}

export interface IncludedEventProfilesSummary {
  eventProfileId: string;
  fullName: string;
  currentlyElected: boolean;
}

export interface EnrollmentEnriched extends PaymentAccount {
  planName: string;
  elections: ElectionEnriched[];
}

export interface ElectionEnriched extends PaymentElection {
  planName: string;
  tier: string;
  currentAmount: number;
  basePrice: number;
  adjustmentAmount?: number;
  adjustmentEndDate?: string;
  adjustmentDurationType?: AdjustmentDurationType;
  feeAmount?: number;
  surchargeAmount?: number;
  isProratedFirstMonth?: boolean;
  isProratedLastMonth?: boolean;
  prorationAmountFirstMonth?: number;
  prorationAmountLastMonth?: number;
  pricingModelId: string;
  pricingEffectiveDate: string;
  electedPartiesSummary: ElectedPartySummary[];
}

export interface ElectedPartySummary {
  eventProfileId: string;
  fullName: string;
}

export interface ProfileHistoryItem {
  eventProfileId?: string;
  fullName?: Name;
  tierHistory?: TierHistoryItem[];
}

export interface TierHistoryItem {
  tier: string;
  startDate: string;
  endDate?: string;
  currentlyElected: boolean;
  paymentElectionIds: string[];
}

export interface CoverageState {
  processingDate: string;
  rawInternalState: object[];
  pagMetaState: object[];
  virtualPagState: object[];
  computedCoverageState: object[];
}

export interface CoverageChangeReportLineSummary {
  paymentAccountGroupId: string;
  profileId: string;
  planGroup: string;
  planId: string;
  electionId: string;
  offeringPriceId: string;
  lineType: CoverageChangeReportLineActionType;
  effectiveDate: string;
}

export interface PlanPeriodRate {
  billingPeriodStart: string;
  planId: string;
  amount: number;
  tier: string;
  planName: string;
}

export type HoldInactivations = HoldInactivation[];

export type PaymentAccounts = PaymentAccount[];

export type PaymentElections = PaymentElection[];

export type PaymentElectionProfiles = PaymentElection[];

export type PaymentAccountGroups = PaymentAccountGroup[];

export type Obligations = Obligation[];

export type ObligationLineItems = ObligationLineItem[];

export type CoverageChangeRuns = CoverageChangeRun[];

export type CoverageChangeStates = CoverageChangeState[];

export type CoverageChangeReportLiness = CoverageChangeReportLine[];

export type BcsRateChangeStates = BcsRateChangeState[];

export enum HoldInactivationCommandType {
  StartToActive = 'StartToActive',
  ActiveToReplaced = 'ActiveToReplaced',
  ActiveToRemoved = 'ActiveToRemoved',
  ReplacedToRemoved = 'ReplacedToRemoved',
}

export enum PaymentAccountCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToInactive = 'ActiveToInactive',
  ActiveToEnrollXf = 'ActiveToEnrollXf',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToActive = 'InactiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToRemoved = 'InactiveToRemoved',
}

export enum PaymentElectionCommandType {
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  ActiveToActive = 'ActiveToActive',
  ActiveToReplaced = 'ActiveToReplaced',
  ReplacedToRemoved = 'ReplacedToRemoved',
}

export enum PaymentElectionProfileCommandType {
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum PaymentAccountGroupCommandType {
  StartToActive = 'StartToActive',
  ActiveToActive = 'ActiveToActive',
  ActiveToProcessing = 'ActiveToProcessing',
  ProcessingToActive = 'ProcessingToActive',
  StartToProcessing = 'StartToProcessing',
  AnyToSynchronize = 'AnyToSynchronize',
  ActiveToInactive = 'ActiveToInactive',
  AnyToSynchronizeObligations = 'AnyToSynchronizeObligations',
  AnyToUpdateObligations = 'AnyToUpdateObligations',
  InactiveToInactive = 'InactiveToInactive',
  InactiveToActive = 'InactiveToActive',
  ActiveToRemoved = 'ActiveToRemoved',
  InactiveToRemoved = 'InactiveToRemoved',
  AnyToError = 'AnyToError',
}

export enum ObligationCommandType {
  CommittedToCommitted = 'CommittedToCommitted',
  CommittedToRemitted = 'CommittedToRemitted',
  CommittedToRemoved = 'CommittedToRemoved',
  CommittedToUncommitted = 'CommittedToUncommitted',
  CommittedToVoid = 'CommittedToVoid',
  DesignatedToCommitted = 'DesignatedToCommitted',
  DesignatedToDesignated = 'DesignatedToDesignated',
  DesignatedToRemoved = 'DesignatedToRemoved',
  DesignatedToUnpaid = 'DesignatedToUnpaid',
  DesignatedToVoid = 'DesignatedToVoid',
  PaymentMissedToPaymentMissed = 'PaymentMissedToPaymentMissed',
  PaymentMissedToRemoved = 'PaymentMissedToRemoved',
  PaymentMissedToUnpaid = 'PaymentMissedToUnpaid',
  PaymentMissedToVoid = 'PaymentMissedToVoid',
  RemittedToRemitted = 'RemittedToRemitted',
  RemittedToRemoved = 'RemittedToRemoved',
  RemittedToUnremitted = 'RemittedToUnremitted',
  RemittedToVoid = 'RemittedToVoid',
  StartToUnpaid = 'StartToUnpaid',
  UncommittedToDesignated = 'UncommittedToDesignated',
  UncommittedToPaymentMissed = 'UncommittedToPaymentMissed',
  UncommittedToRemoved = 'UncommittedToRemoved',
  UncommittedToUncommitted = 'UncommittedToUncommitted',
  UnpaidToDesignated = 'UnpaidToDesignated',
  UnpaidToPaymentMissed = 'UnpaidToPaymentMissed',
  UnpaidToRemitted = 'UnpaidToRemitted',
  UnpaidToRemoved = 'UnpaidToRemoved',
  UnpaidToUnpaid = 'UnpaidToUnpaid',
  UnpaidToVoid = 'UnpaidToVoid',
  UnremittedToDesignated = 'UnremittedToDesignated',
  UnremittedToPaymentMissed = 'UnremittedToPaymentMissed',
  UnremittedToRemitted = 'UnremittedToRemitted',
  UnremittedToRemoved = 'UnremittedToRemoved',
  UnremittedToUnremitted = 'UnremittedToUnremitted',
  UncommittedToVoid = 'UncommittedToVoid',
  UnremittedToVoid = 'UnremittedToVoid',
  VoidToVoid = 'VoidToVoid',
  VoidToRemoved = 'VoidToRemoved',
}

export enum ObligationLineItemCommandType {
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum CoverageChangeRunCommandType {
  StartToProcessing = 'StartToProcessing',
  AnyToProgressRun = 'AnyToProgressRun',
  AnyToUpdateQueuedCount = 'AnyToUpdateQueuedCount',
  ProcessingToComplete = 'ProcessingToComplete',
  ProcessingToError = 'ProcessingToError',
  ErrorToRemoved = 'ErrorToRemoved',
}

export enum CoverageChangeStateCommandType {
  StartToProcessed = 'StartToProcessed',
  ProcessedToRemoved = 'ProcessedToRemoved',
}

export enum CoverageChangeReportLineCommandType {
  StartToActive = 'StartToActive',
  ActiveToRemoved = 'ActiveToRemoved',
}

export enum BcsRateChangeStateCommandType {
  AnyToDetectRateChanges = 'AnyToDetectRateChanges',
}

export enum HoldInactivationState {
  Active = 'Active',
  Replaced = 'Replaced',
  Removed = 'Removed',
}

export enum PaymentAccountState {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
}

export enum PaymentElectionState {
  Active = 'Active',
  Removed = 'Removed',
  Replaced = 'Replaced',
}

export enum PaymentElectionProfileState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum PaymentAccountGroupState {
  Any = 'Any',
  Active = 'Active',
  Processing = 'Processing',
  Inactive = 'Inactive',
  Synchronize = 'Synchronize',
  SynchronizeObligations = 'SynchronizeObligations',
  UpdateObligations = 'UpdateObligations',
  Removed = 'Removed',
  Error = 'Error',
  FIFO = 'FIFO',
}

export enum ObligationState {
  Unpaid = 'Unpaid',
  PaymentMissed = 'PaymentMissed',
  Designated = 'Designated',
  Committed = 'Committed',
  Remitted = 'Remitted',
  Uncommitted = 'Uncommitted',
  Removed = 'Removed',
  Unremitted = 'Unremitted',
  Void = 'Void',
}

export enum ObligationLineItemState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum CoverageChangeRunState {
  Processing = 'Processing',
  Completed = 'Completed',
  ProgressRun = 'ProgressRun',
  UpdateQueuedCount = 'UpdateQueuedCount',
  Error = 'Error',
}

export enum CoverageChangeStateState {
  Processed = 'Processed',
  Removed = 'Removed',
}

export enum CoverageChangeReportLineState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum BcsRateChangeStateState {
  Active = 'Active',
  Removed = 'Removed',
}

export enum SubmissionPlatformType {
  Admin = 'Admin',
  Pux = 'Pux',
  Clux = 'Clux',
  System = 'System',
}

export enum PaymentStatusType {
  Unpaid = 'Unpaid',
  PaymentRecieved = 'PaymentRecieved',
  Missed = 'Missed',
  Paid = 'Paid',
  Remitted = 'Remitted',
}

export enum AddressType {
  Primary = 'Primary',
  Billing = 'Billing',
  Shipping = 'Shipping',
  Other = 'Other',
}

export enum CoverageChangeType {
  Add = 'Add',
  Cancel = 'Cancel',
}

export enum ProfileType {
  Bureau = 'Bureau',
  Client = 'Client',
  Dependent = 'Dependent',
  Individual = 'Individual',
  Instance = 'Instance',
  Payee = 'Payee',
}

export enum EventProfileType {
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
}

export enum ParentType {
  INSTANCE = 'INSTANCE',
  CLIENT = 'CLIENT',
  INDIVIDUAL = 'INDIVIDUAL',
  DEPENDENT = 'DEPENDENT',
  ENTRY = 'ENTRY',
  ELIGIBILITY_EVENT = 'ELIGIBILITY_EVENT',
  ELIGIBILITY_EVENT_OFFERING = 'ELIGIBILITY_EVENT_OFFERING',
  PAYMENT_ACCOUNT_GROUP = 'PAYMENT_ACCOUNT_GROUP',
  PAYMENT_ACCOUNT = 'PAYMENT_ACCOUNT',
  PAYMENT_ELECTION = 'PAYMENT_ELECTION',
  OBLIGATION = 'OBLIGATION',
}

export enum HoldingAccountType {
  UHA = 'UHA',
  DHA = 'DHA',
  RSA = 'RSA',
}

export enum BalanceSourceType {
  XF = 'XF',
  EntryPostings = 'EntryPostings',
}

export enum FeeType {
  Flat = 'Flat',
  Percentage = 'Percentage',
}

export enum RoundingType {
  Up = 'Up',
  Down = 'Down',
  Standard = 'Standard',
}

export enum HideFromType {
  None = 'None',
  All = 'All',
  ParticipantOnly = 'ParticipantOnly',
  ClientOnly = 'ClientOnly',
  ParticipantAndClientOnly = 'ParticipantAndClientOnly',
}

export enum HoldInactivationReasonType {
  InsignificantShortage = 'InsignificantShortage',
}

export enum InactivationReasonType {
  ClientCancellation = 'ClientCancellation',
  PlanClosed = 'PlanClosed',
  EndOfEligibility = 'EndOfEligibility',
  UnenrolledPriorToPayment = 'UnenrolledPriorToPayment',
  Unenrolled = 'Unenrolled',
  MissedPayment = 'MissedPayment',
}

export enum GenderType {
  Male = 'Male',
  Female = 'Female',
  NonBinary = 'NonBinary',
  OptionNotListed = 'OptionNotListed',
  Unknown = 'Unknown',
}

export enum RelationshipType {
  Spouse = 'Spouse',
  Child = 'Child',
  DomesticPartner = 'DomesticPartner',
  ExSpouse = 'ExSpouse',
  SurvivingSpouse = 'SurvivingSpouse',
  Other = 'Other',
}

export enum ObligationAmountType {
  Base = 'Base',
  Fee = 'Fee',
  Surcharge = 'Surcharge',
}

export enum PaymentAccountGroupFifoMessageType {
  ObligationGenerationJob = 'ObligationGenerationJob',
  ObligationSynchronization = 'ObligationSynchronization',
  Synchronization = 'Synchronization',
  Inactivate = 'Inactivate',
  SetFirstPaymentDate = 'SetFirstPaymentDate',
}

export enum ObligationType {
  Disability = 'Disability',
  Standard = 'Standard',
  Correction = 'Correction',
  Credit = 'Credit',
  PriorityFee = 'PriorityFee',
}

export enum AdjustmentDurationType {
  NumberOfMonths = 'NumberOfMonths',
  UntilCoverageEnds = 'UntilCoverageEnds',
  CustomEndDate = 'CustomEndDate',
}

export enum CoverageChangeReportLineActionType {
  ADD = 'ADD',
  CANCEL = 'CANCEL',
  CHANGE = 'CHANGE',
  NEEDS_REVIEW_ADD = 'NEEDS_REVIEW_ADD',
  NEEDS_REVIEW_CANCEL = 'NEEDS_REVIEW_CANCEL',
  NEEDS_REVIEW_CHANGE = 'NEEDS_REVIEW_CHANGE',
}

export enum TPAPartnerType {
  TASC = 'TASC',
  BASIC = 'BASIC',
}

export enum ChainType {
  AND = 'AND',
  OR = 'OR',
}

export enum MatchType {
  CONTAINS = 'CONTAINS',
  EXACT = 'EXACT',
  NOT = 'NOT',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  NOT_CONTAINS = 'NOT_CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  NOT_STARTS_WITH = 'NOT_STARTS_WITH',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  IS_NULL = 'IS_NULL',
}

export enum AggregateType {
  SUM = 'SUM',
  COUNT = 'COUNT',
}

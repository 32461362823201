import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TaxYearLimitState, TaxYearLimitStore } from './taxYearLimit.store';

@Injectable({
  providedIn: 'root',
})
export class TaxYearLimitQuery extends QueryEntity<TaxYearLimitState> {
  public constructor(protected store: TaxYearLimitStore) {
    super(store);
  }
}

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CurrencyMaskConfig, NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { JwtInterceptor } from './auth/services/jwt-interceptor.service';
import { HttpDEBUGInterceptor } from './debug-endpoints';
import { LoginModule } from './login/login.module';
import { RaygunModule } from './raygun/raygun.module';
import { IntlPolyfillService } from './shared/services/intl-polyfill.service';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';

const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$',
  suffix: '',
  thousands: ',',
  nullable: true,
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    /** Angular modules */
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    /** Our modules */
    AppRoutingModule,
    LoginModule,
    RaygunModule,
    /** Third-party modules */
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    NgxUiLoaderModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 0,
      extendedTimeOut: 0,
      tapToDismiss: true,
      disableTimeOut: true,
      closeButton: true,
      positionClass: 'inline',
    }),
    ToastContainerModule,
    TooltipModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    SharedModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (intlPolyfillService: IntlPolyfillService) => () => intlPolyfillService.loadDynamicPolyfills(),
      deps: [IntlPolyfillService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    // @ts-ignore
      ...environment.enableDebugInterceptors ? [{ provide: HTTP_INTERCEPTORS, useClass: HttpDEBUGInterceptor, multi: true }] : [],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(@Optional() @SkipSelf() self: AppModule) {
    if (self) {
      throw new Error(
        'AppModule is already loaded. Do not import AppModule.');
    }
  }
}

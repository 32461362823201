import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cacheable, withTransaction } from '@datorama/akita';
import { IndividualPaymentStatus, PaymentAccountEnriched, PaymentAccountGroupEnriched } from '@models/paymentAccount/model';
import { orderBy } from 'lodash';
import { Observable, interval } from 'rxjs';
import { catchError, filter, map, mergeMap, take, timeout } from 'rxjs/operators';
import { IndividualPaymentStatusStore, IndividualQuery } from 'src/app/state';
import { CoreService } from '../../models/pux';
import { ErrorHandlingService } from '../error-handling.service';
import { Uri } from '../uri';

@Injectable({
  providedIn: 'root',
})
export class IndividualPaymentStatusService {
  public constructor(
    private errorHandlingService: ErrorHandlingService,
    private http: HttpClient,
    private individualQuery: IndividualQuery,
    private individualPaymentStatusStore: IndividualPaymentStatusStore,
  ) { }

  public loadIndividualPaymentStatus(): Observable<IndividualPaymentStatus> {
    const individualId = this.individualQuery.getActiveId();
    this.individualPaymentStatusStore.setLoading(true);
    const paymentStatusSummaryUri = new Uri(`/profile/${individualId}/profileType/individual/paymentStatus`, CoreService.PaymentAccount);
    const request$ = this.httpGet<IndividualPaymentStatus>(paymentStatusSummaryUri)
      .pipe(
        map((individualPaymentStatus) => this.orderIndividualPaymentStatus(individualPaymentStatus)),
        withTransaction((paymentStatusSummary) => {
          this.individualPaymentStatusStore.update(paymentStatusSummary);
          this.individualPaymentStatusStore.setLoading(false);
        }),
        catchError((err) => {
          this.individualPaymentStatusStore.setLoading(false);
          throw err;
        }),
      );

    return cacheable(this.individualPaymentStatusStore, request$, { emitNext: true });
  }

  public waitForPaymentStatusToUpdate(newPagId: string, version: number): Observable<IndividualPaymentStatus> {
    const startTime = Date.now();
    const timeoutMs = 30000;
    const individualId = this.individualQuery.getActiveId();
    const paymentStatusSummaryUri = new Uri(`/profile/${individualId}/profileType/individual/paymentStatus`, CoreService.PaymentAccount);

    return interval(2000).pipe(
      mergeMap(() => this.httpGet<IndividualPaymentStatus>(paymentStatusSummaryUri)),
      filter((ips) => ips.paymentAccountGroups.some((pag) => pag.id === newPagId && pag.version >= version)),
      take(1),
      timeout(timeoutMs),
      catchError((_) => {
        if (Date.now() - startTime >= timeoutMs) {
          throw Error('Timed out trying to save elections, operation may still complete successfully.');
        } else {
          throw Error('There is a problem making this election, please try again.');
        }
      }),
    );
  }

  private orderIndividualPaymentStatus(individualPaymentStatus: IndividualPaymentStatus): IndividualPaymentStatus {
    return {
      ...individualPaymentStatus,
      paymentAccountGroups: this.orderPaymentAccountGroupsElections(
        orderBy(individualPaymentStatus.paymentAccountGroups, ['currentTotalElectionAmountNonProrated'], ['desc']),
      ),
    };
  }

  private orderPaymentAccountGroupsElections(paymentAccountGroups: PaymentAccountGroupEnriched[]): PaymentAccountGroupEnriched[] {
    return paymentAccountGroups.map((pag) => ({
      ...pag,
      accounts: pag.accounts.map((a) => this.orderElections(a)),
    }));
  }

  private orderElections(paymentAccountEnriched: PaymentAccountEnriched): PaymentAccountEnriched {
    return {
      ...paymentAccountEnriched,
      currentElections: orderBy(paymentAccountEnriched.currentElections, ['currentAmount', 'tier'], ['desc']),
    };
  }

  private httpGet<T>(uri: Uri): Observable<T> {
    return this.http.get<T>(uri.toString())
      .pipe(catchError(this.errorHandlingService.rxjsErrorHandler()));
  }
}


import {of as observableOf, timer as observableTimer, from as observableFrom,  Observable ,  BehaviorSubject ,  Subject } from 'rxjs';

import {switchMap, map, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Dates } from '../utils/dates';
import { Dayjs } from 'dayjs';

export type UnitOfTime = 'minutes' | 'seconds' | 'milliseconds';

export interface RouteLoadingEvent {
  isLoading: boolean;
  timestamp: Dayjs;
}

@Injectable()
export class RouteLoadingService implements OnDestroy {
  private isRouteLoadingSubject = new BehaviorSubject<RouteLoadingEvent>(this.createRouteLoadingEvent(false));
  private unsubscribe$ = new Subject<void>();

  private get routeLoadingEvents$(): Observable<RouteLoadingEvent> {
    return this.isRouteLoadingSubject.asObservable().pipe(distinctUntilChanged());
  }

  public constructor(router: Router) {
    router.events.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((event) => {
      try {
          if (event instanceof NavigationStart) {
              this.routeLoadingStarted();
          } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
              this.routeLoadingEnded();
          }
      } catch {
          this.routeLoadingEnded();
      }
    });
  }

  public hasRouteLoadingTimeExceeded(duration: number, unit: UnitOfTime = 'milliseconds'): Observable<boolean> {
    return this.routeLoadingEvents$.pipe(switchMap((event: RouteLoadingEvent) => {
      if (event.isLoading) {
        const elapsedTimePromise = this.getElapsedTimeSinceRouteLoadingStarted(event, unit);
        const initialDelayPromise = elapsedTimePromise.then((elapsedTime) => Math.max(0, duration - elapsedTime));
        return observableFrom(initialDelayPromise).pipe(
          switchMap((initialDelay: any) => observableTimer(initialDelay).pipe(map(() => true))));
      }
      return observableOf(false);
    }));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public routeLoadingStarted(): void {
    const event = this.createRouteLoadingEvent(true);
    this.isRouteLoadingSubject.next(event);
  }

  public routeLoadingEnded(): void {
    const event = this.createRouteLoadingEvent(false);
    this.isRouteLoadingSubject.next(event);
  }

  private createRouteLoadingEvent(isLoading: boolean): RouteLoadingEvent {
    return {
      isLoading,
      timestamp: Dates.now(),
    };
  }

  private async getElapsedTimeSinceRouteLoadingStarted(event: RouteLoadingEvent, unit: UnitOfTime): Promise<number> {
      return Dates.now().diff(event.timestamp, unit);
  }
}

<app-route-loading [label]="'Loading terms of use'" [spin]="spin"></app-route-loading>
<div [ngClass]="signState">
  <main class="container">
    <app-terms-of-use-signature
      (loaded)="termsOfUseLoaded()"
      (signedTermsOfUse)="signedTermsOfUse()">
    </app-terms-of-use-signature>
  </main>
  <app-footer></app-footer>
</div>

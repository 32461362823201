import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DayJsInclusivity } from 'src/app/shared/models/pux';
import { BenefitPlan, BenefitPlanFundingSourceAndSchedule } from 'src/app/shared/models/uba/configuration/model';
import { Dates } from 'src/app/shared/utils';

import {
  BenefitPlanFundingSourceAndScheduleState,
  BenefitPlanFundingSourceAndScheduleStore,
} from './benefit-plan-funding-source-and-schedule.store';

@Injectable({
  providedIn: 'root',
})
export class BenefitPlanFundingSourceAndScheduleQuery extends QueryEntity<BenefitPlanFundingSourceAndScheduleState> {
  public constructor(protected store: BenefitPlanFundingSourceAndScheduleStore) {
    super(store);
  }

  public selectByBenefitPlan(benefitPlan: BenefitPlan, forEnrollment: boolean = false): Observable<BenefitPlanFundingSourceAndSchedule[]> {
    return this.selectLoading()
      .pipe(
        filter((isLoading) => !isLoading),
        switchMap(() => this.selectAll({
          filterBy: (entity) => entity.parentId === benefitPlan.id,
        })),
        map((benefitPlanFundingSourcesAndSchedules) => {
          // return all BPFSS for open ended plans
          if (!benefitPlan.scheduleEndDate || (!benefitPlan.planEndDate && !forEnrollment) || !benefitPlanFundingSourcesAndSchedules.length) {
            return benefitPlanFundingSourcesAndSchedules;
          }
          if (forEnrollment) {
            const furthestFutureStartDate = benefitPlanFundingSourcesAndSchedules.reduce((prev, curr) => Dates.isAfter(curr.startDate, prev.startDate) ? curr : prev).startDate;
            return benefitPlanFundingSourcesAndSchedules.filter((bpfss) => bpfss.startDate === furthestFutureStartDate);
          }
          return benefitPlanFundingSourcesAndSchedules.filter((bpfss) => Dates.isBetween(Dates.today(), bpfss.startDate, bpfss.endDate, DayJsInclusivity.InclusiveStartExclusiveEnd));
        }),
      );
  }
}

import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { BrandId } from 'src/app/shared/models/pux';
import { BrandService } from 'src/app/shared/services/brand.service';

import { TermsOfUseService } from '../../services/terms-of-use.service';

@Component({
  selector: 'app-terms-of-use-signature',
  templateUrl: './terms-of-use-signature.component.html',
  styleUrls: ['./terms-of-use-signature.component.scss'],
})
export class TermsOfUseSignatureComponent implements OnInit, OnDestroy {
  @ViewChild('termsIframe', { static: true }) public termsOfUseIframe: ElementRef<HTMLIFrameElement>;
  @Output() public loaded = new EventEmitter<void>();
  @Output() public signedTermsOfUse = new EventEmitter<void>();
  public readonly brand = this.brandService.getBrandResources();
  public readonly brandId = this.brandService.getBrand();
  public readonly BrandId = BrandId;
  public readonly iframeUrl = `assets/terms-of-use/${this.brand.termsOfUseFileName}`;
  public spin: boolean = false;
  private subscriptions = new Subscription();

  public constructor(
    private brandService: BrandService,
    private termsOfUseService: TermsOfUseService,
  ) { }

  public ngOnInit(): void {
    this.loaded.emit();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public printTermsOfUse(): void {
    if (this.termsOfUseIframe && this.termsOfUseIframe.nativeElement) {
      this.termsOfUseIframe.nativeElement.contentWindow.print();
    }
  }

  public signTermsOfUse(): void {
    this.spin = true;
    const subscription = this.termsOfUseService.signTermsOfUse()
      .subscribe(() => {
        this.spin = false;
        this.signedTermsOfUse.emit();
      });
    this.subscriptions.add(subscription);
  }
}

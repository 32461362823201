import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  public constructor(
    private toastr: ToastrService,
    private router: Router,
  ) { }

  public rxjsErrorHandler<T>(errorMessage?: (err: any) => string, action?: (err: any) => void, response?: (err: any) => Observable<T>): (err: any, caught: Observable<T>) => Observable<T> {
    return (err: any, _caught: Observable<T>) => {
      const message = (errorMessage && errorMessage(err)) || 'Something went wrong';
      this.toastr.error(message);
      if (action) {
        action(err);
      }
      return response ? response(err) : throwError(err);
    };
  }

  public requestDetailsResponse<T>(errorMessage?: (err: any) => string, action?: (err: any) => void, response?: (err: any) => Observable<T>): (err: any, caught: Observable<T>) => Observable<T> {
    return (err: any, _caught: Observable<T>) => {
      const message = (errorMessage && errorMessage(err)) || 'Something went wrong when retrieving support request details';
      this.toastr.error(message);
      if (action) {
        action(err);
      }
      setTimeout(async () => {
        await this.router.navigate(['support/history']);
      }, 3000);
      return response ? response(err) : throwError(err);
    };
  }

  public supportRequestAttachment<T>(errorMessage?: (err: any) => string, action?: (err: any) => void, response?: (err: any) => Observable<T>): (err: any, caught: Observable<T>) => Observable<T> {
    return (err: any, _caught: Observable<T>) => {
      const message = (errorMessage && errorMessage(err)) || 'Something went wrong when retrieving support request attachment';
      this.toastr.error(message);
      if (action) {
        action(err);
      }
      return response ? response(err) : throwError(err);
    };
  }

  public supportRequestCommentErrorHandler<T>(errorMessage?: (err: any) => string, action?: (err: any) => void, response?: (err: any) => Observable<T>): (err: any, caught: Observable<T>) => Observable<T> {
    return (err: any, _caught: Observable<T>) => {
      const message = (errorMessage && errorMessage(err)) || 'Something went wrong while submitting the comment. Please try again.';
      this.toastr.error(message);
      if (action) {
        action(err);
      }
      return response ? response(err) : throwError(err);
    };
  }

  public fileUploadErrorHandler<T>(errorMessage?: (err: any) => string, action?: (err: any) => void, response?: (err: any) => Observable<T>): (err: any, caught: Observable<T>) => Observable<T> {
    return (err: any, _caught: Observable<T>) => {
      const message = (errorMessage && errorMessage(err)) || 'One or more attachments have failed to Upload. Please try again.';
      this.toastr.error(message);
      if (action) {
        action(err);
      }
      return response ? response(err) : throwError(err);
    };
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IndividualPaymentStatusService } from '@app/shared/services/api/individual-payment-status.service';
import { Client } from '@models/profile/model';
import { EmploymentInfo } from '@models/profileConfiguration/model';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, from, Observable, of, zip } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mapTo,
  shareReplay,
  skipWhile,
  switchMap,
  switchMapTo,
} from 'rxjs/operators';

import {AdhocPaymentSourceAccountService} from '@app/shared/services/api/adhoc-payment-source-account.service';
import { BenefitPlansService } from '../benefit-accounts/services/benefit-plans.service';
import { PaymentEnrollmentViewModelStore } from '../enrollment/ui-state/payment-enrollment-view-model';
import { DependentService } from '../reimbursement/service/dependent.service';
import { UserProfileService } from '../settings/services/user-profile.service';
import { FeatureAccount } from '../shared/models/uba/account/model';
import { BenefitPlan } from '../shared/models/uba/configuration/model';
import { BankAccountService } from '../shared/services/api/bank-account.service';
import { BenefitAccountService } from '../shared/services/api/benefit-account.service';
import { CustomizationService } from '../shared/services/api/customization.service';
import { DocumentService } from '../shared/services/api/document.service';
import { FundsTransferCriterionService } from '../shared/services/api/funds-transfer-criterion.service';
import { LetterService } from '../shared/services/api/letter.service';
import { PaymentSourceAccountService } from '../shared/services/api/payment-source-account.service';
import { TradeService } from '../shared/services/api/trade.service';
import { TransactionService } from '../shared/services/api/transaction.service';
import { BrandService } from '../shared/services/brand.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { CardPackageService } from '../tasc-wallet/services/card-package.service';
import { CardService } from '../tasc-wallet/services/card.service';
import { TermsOfUseService } from '../terms-of-use/services/terms-of-use.service';
import { CurrentUserQuery } from './current-user';

interface HighPriorityData {
  benefitPlans: BenefitPlan[];
  client: Client;
  employmentInfo: EmploymentInfo;
  featureAccounts: FeatureAccount[];
}

@Injectable({
  providedIn: 'root',
})
export class PrefetchService {
  public constructor(
    private bankAccountService: BankAccountService,
    private benefitAccountService: BenefitAccountService,
    private benefitPlansService: BenefitPlansService,
    private brandService: BrandService,
    private cardService: CardService,
    private cardPackageService: CardPackageService,
    private currentUserQuery: CurrentUserQuery,
    private customizationService: CustomizationService,
    private dependentService: DependentService,
    private documentService: DocumentService,
    private errorHandlingService: ErrorHandlingService,
    private fundsTransferCriterionService: FundsTransferCriterionService,
    private letterService: LetterService,
    private paymentSourceAccountService: PaymentSourceAccountService,
    private individualPaymentStatusService: IndividualPaymentStatusService,
    private router: Router,
    private toastrService: ToastrService,
    private termsOfUseService: TermsOfUseService,
    private tradeService: TradeService,
    private transactionService: TransactionService,
    private userProfileService: UserProfileService,
    private paymentEnrollmentViewModelStore: PaymentEnrollmentViewModelStore,
    private adhocService: AdhocPaymentSourceAccountService,
  ) { }

  public prefetchData(): Observable<boolean> {
    return this.currentUserQuery.isLoggedIn$
      .pipe(
        skipWhile((isLoggedIn) => !isLoggedIn),
        distinctUntilChanged(),
        switchMap((isLoggedIn) => {
          if (isLoggedIn) {
            // prefetch data in priority order
            const staticData$ = this.prefetchHighPriorityData()
              .pipe(
                shareReplay(),
              );
            const refreshedData$ = staticData$
              .pipe(
                switchMap((staticData) => this.prefetchMediumPriorityData(staticData)),
                switchMap((staticData) => this.prefetchLowPriorityData(staticData)),
                map(() => true),
              );
            return refreshedData$;
          }

          return of(false);
        }),
        catchError(this.errorHandlingService.rxjsErrorHandler()),
      );
  }

  private prefetchHighPriorityData(): Observable<HighPriorityData> {
    return this.userProfileService.getLoggedInIndividual()
      .pipe(
        switchMap((individual) => {
          if (!individual) {
            this.toastrService.error('User does not exist.');
            return from(this.router.navigate(['/logout']))
              .pipe(
                switchMapTo(EMPTY),
              );
          }

          return zip(
            this.userProfileService.getEmploymentInfo(),
            this.termsOfUseService.getTermsOfUseDocument(),
          ).pipe(
            switchMap(([employmentInfo, termsOfUseDocument]) => {
              if (!employmentInfo) {
                this.toastrService.error('Employment Info not found.');
                return from(this.router.navigate(['/logout']))
                  .pipe(
                    switchMapTo(EMPTY),
                  );
              }
              if (!termsOfUseDocument) {
                const companyName = this.brandService.getBrandResources()?.companyName;
                this.toastrService.error(`${companyName} Terms of Use not found.`);
                return from(this.router.navigate(['/logout']))
                  .pipe(
                    switchMapTo(EMPTY),
                  );
              }
              return zip(
                this.userProfileService.getClient()
                  .pipe(
                    switchMap((client) => {
                      return zip(
                        this.benefitPlansService.getAllBenefitPlans(client.id),
                        this.benefitPlansService.getFeatureAccounts(client.id),
                        this.termsOfUseService.getSignedDocumentActions(termsOfUseDocument.id, individual.id),
                        this.paymentEnrollmentViewModelStore.load(individual.id),
                      ).pipe(
                        map(([benefitPlans, featureAccounts]) => ({ benefitPlans, client, employmentInfo, featureAccounts })),
                      );
                    }),
                  ),
                this.dependentService.getDependents()
                  .pipe(
                    switchMap((dependents) => {
                      const dependentIds = dependents.map((dependent) => dependent.id);
                      return this.cardService.getCards(dependentIds);
                    }),
                  ),
              ).pipe(
                map(([highPriorityData]) => highPriorityData),
              );
            }),
          );
        }),
      );
  }

  private prefetchMediumPriorityData(data: HighPriorityData): Observable<HighPriorityData> {
    const benefitPlanIds = data.benefitPlans ? data.benefitPlans.map((x) => x.id) : [];
    const requests: Array<Observable<any>> = [
      zip(
        this.benefitPlansService.getAllBenefitAccounts(),
        this.paymentSourceAccountService.getPaymentSourceAccounts(),
      ).pipe(
        switchMap(([benefitAccounts, paymentSourceAccounts]) => {
          const benefitAccountIds = benefitAccounts ? benefitAccounts.map((x) => x.id) : [];
          const benefitAccountSoaIds = benefitAccounts ? benefitAccounts.map((x) => x.soaAccountId).filter((soaAccountId) => !!soaAccountId) : [];
          const benefitAccountChildRequests: Array<Observable<any>> = [
            this.transactionService.getAllTransactions(data.featureAccounts, benefitAccounts, data.benefitPlans, paymentSourceAccounts),
            this.tradeService.getAllTrades(benefitAccounts, data.benefitPlans),
            this.benefitAccountService.getElections(benefitAccountIds, data.client.parentId),
            this.benefitPlansService.getBenefitAccountPostingSummaries(benefitAccountSoaIds),
          ];
          if (benefitAccountIds.length) {
            benefitAccountChildRequests.push(
              this.benefitPlansService.getBenefitAccountInvestmentBalances(benefitAccountIds),
              this.benefitPlansService.getBenefitAccountInvestmentBalanceEntries(benefitAccountIds),
            );
          }
          return zip(...benefitAccountChildRequests);
        }),
      ),
      this.userProfileService.getAlerts(),
      this.userProfileService.getPayroll(data.employmentInfo.clientPayrollId),
      this.benefitPlansService.getPayrollScheduleByPlan(benefitPlanIds),
      this.benefitPlansService.getBenefitPlanFundingSources(benefitPlanIds),
      this.benefitPlansService.getAllTaxYearLimits(),
    ];

    return zip(...requests)
      .pipe(
        mapTo(data),
      );
  }

  private prefetchLowPriorityData(data: HighPriorityData): Observable<any[]> {
    const requests: Array<Observable<any>> = [
      this.customizationService.getClientCustomizationSettings(),
      this.bankAccountService.getBankAccounts(),
      this.cardPackageService.loadCardPackageAndCacheImage(),
      this.documentService.getClientDocuments(data.client.id),
      this.letterService.getLetters(data.employmentInfo.individualId),
      this.adhocService.loadAdhocPaymentAccounts(),
      this.fundsTransferCriterionService.loadScheduledFundsTransfer(),
      this.fundsTransferCriterionService.loadFundsTransferOptions(),
      this.individualPaymentStatusService.loadIndividualPaymentStatus(),
    ];

    return zip(...requests);
  }
}

<div class="login-container text-left">
  <div class="d-flex w-100">
    <div class="col-1 pr-0">
      <app-login-header></app-login-header>
    </div>
    <div style="order: 1" class="login-bg col-lg-7 position-relative {{brand.brandCssClass}}">
      <div class="login-bg-wrapper">
        <div class="login-bg-text">WELCOME <br>TO {{brand.companyName}}</div>
        <span class="separator"></span>
      </div>
    </div>
    <div style="order: 0" class="login-form d-flex col-lg-4 pl-0">
      <div class="login-col">
        <router-outlet></router-outlet>
        <div class="pt-2 login-instruction">
          <div class="first-info pb-lg-4 pb-3" *ngIf="brandId !== BrandId.Crossroads">
            <h2 class="mb-0">First time here? </h2>
            <p><a data-cy="signUpLink" class="btn-link font-regular" routerLink="/signup">Sign up</a> to establish access to your new account</p>
          </div>
          <ng-container *ngIf="brandId === BrandId.TASC">
            <div class="need-info">
              <h2 class="mb-0">Need help?</h2>
              <p><span >Watch the video below or </span><a href="assets/docs/signin-instructions.pdf" target="_blank" class="btn-link">download instructions</a></p>
            </div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe title="Accessing {{brand.productName}}" class="embed-responsive-item" src="https://player.vimeo.com/video/1013204766?h=227b87031b" allowfullscreen></iframe>
            </div>
          </ng-container>
          <div class="row other-information mt-lg-5 mt-md-20">
            <div class="col-12">
              <p>By continuing you agree to the
                <button tabindex="0" data-cy="terms-of-use" type="button" class="no-border-btn btn-link font-regular" (click)="openTermsOfUse()">Terms of Use</button>
                and
                <button tabindex="0" type="button" class="no-border-btn btn-link font-regular" (click)="openPrivacyPolicy()">Privacy Notice</button></p>
            </div>
          </div>
          <div class="row other-information">
            <div class="col-lg-6 order-lg-last">
              <p data-cy="phone-number" class="pt-1">Trouble signing in? Call <span class="trouble-number">{{brand.phoneNumber}}</span></p>
            </div>
            <div class="col-lg-6">
              <p><i class="icon-lock mr-3" aria-hidden="true"></i>Your information is secure</p>
            </div>
          </div>
        </div>
        <app-terms-of-use-modal></app-terms-of-use-modal>
      </div>
    </div>
  </div>
  <footer class="signup-footer text-right">
    <p class="mb-1 font-12 copyright">&copy; Copyright {{currentYear}} {{brand.companyNameCopyright}}. All rights reserved.</p>
    <p class="font-12 text-center">V{{ version }}</p>
  </footer>
</div>
<app-unsupported-browser-modal></app-unsupported-browser-modal>
